import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Container, Button } from 'react-bootstrap';
import SchedulingHUD from '../core/SchedulingHUD';

import ScheduleRing from '../core/ScheduleRing';

// import { updateSchedule } from '../../redux/actions/jumpActions';
import {
  fetchEntries,
  fetchEventDivisions,
  fetchRings,
} from '../../../redux/actions/Events/entryActions';

import { useDispatch, useSelector } from 'react-redux';
const _ = require('lodash');

export function SchedulingPage() {
  const dispatch = useDispatch();

  const [selectedEntry, setSelectedEntry] = useState(null);
  const [selectedEntryList, setSelectedEntryList] = useState([]);

  useEffect(() => {
    dispatch(fetchEntries());
    dispatch(fetchEventDivisions());
    // dispatch(fetchBlocks(115));
    dispatch(fetchRings(115));
  }, [dispatch]);

  const ring = useSelector((state) => state.rings[1]);
  const ring2 = useSelector((state) => state.rings[2]);
  // const rings = useSelector((state) => state.rings);
  // const blocks = useSelector((state) => state.scheduleBlocks);
  const eventDivisions = useSelector((state) => state.eventDivisions);
  const entries = useSelector((state) => state.entries);

  function mapEventDivisionsToEntries(entries) {
    entries.forEach((entry) => {
      var eventDivision = _.find(eventDivisions, ['id', entry.eventDivisionId]);
      if (eventDivision) {
        entry.eventDivision = eventDivision;
      }
    });
  }

  function handleSelectEntry(entry) {
    if (selectedEntry) {
      selectedEntry.selected = false;
    }
    if (!selectedEntry || selectedEntry.id !== entry.id) {
      entry.selected = true;
      setSelectedEntry(entry);
      var riderArray = _.filter(entries, (entryItem) => {
        return entryItem.riderId === entry.riderId;
      });
      setSelectedEntryList(riderArray);
    } else {
      setSelectedEntry(null);
      setSelectedEntryList([]);
    }
  }

  function renderRing(ring) {
    if (ring && entries) {
      var dressageEntries = _.filter(entries, function (entry) {
        return (
          entry.rideTimes &&
          entry.rideTimes.dressage &&
          entry.rideTimes.dressage.ringId === parseInt(ring.id)
        );
      });
      mapEventDivisionsToEntries(dressageEntries);
      return (
        <ScheduleRing
          startTime="October 13, 2023 08:00:00 AM"
          durationHours="5"
          ring={ring}
          entries={dressageEntries}
          onSelectEntry={handleSelectEntry}
        />
      );
    }
  }

  const onSaveClick = () => {
    // console.log('saveSchedule');
    // var schedule = {};
    // schedule.rings = rings;
    // schedule.blocks = blocks;
    // dispatch(updateSchedule({ id: 1, schedule }));
    var dressageEntries = _.filter(entries, function (entry) {
      return entry.rideTimes && entry.rideTimes.dressage;
    });
    console.log(dressageEntries);
  };

  return (
    <Container>
      <Row>
        <Button onClick={onSaveClick}>Save</Button>
        <Col xs={5} md={5}>
          <Card bg="dark">
            <Card.Header>
              <Card.Title>HUD</Card.Title>
            </Card.Header>
            <Card.Body>
              <SchedulingHUD
                entries={entries}
                eventDivisions={eventDivisions}
                selectedEntry={selectedEntry}
                selectedEntries={selectedEntryList}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col xs={3} md={3}>
          <Card bg="dark">
            <Card.Header>
              <Card.Title>Dressage</Card.Title>
            </Card.Header>
            <Card.Body>{renderRing(ring)}</Card.Body>
          </Card>
        </Col>
        <Col xs={3} md={3}>
          <Card bg="dark">
            <Card.Header>
              <Card.Title>Stadium</Card.Title>
            </Card.Header>
            <Card.Body>{renderRing(ring2)}</Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default SchedulingPage;
