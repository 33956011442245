import React from 'react';
import { Image, Button } from 'react-bootstrap';
import moment from 'moment';
import ModalImage from 'react-modal-image';
import { useDispatch } from 'react-redux';
import { Divider, Stack } from '@mui/material';

export default function HorsePhotoCard({
  horse,
  horsePhoto,
  onDelete,
  onMakeMainPhoto,
}) {
  const dispatch = useDispatch();

  function dateToText(date) {
    return <p>{moment(date).format('M/D/YY - hh:mm a')}</p>;
  }

  function renderImgTag() {
    if (
      horsePhoto &&
      horsePhoto.amazons3URLHalf &&
      horsePhoto.amazons3URLHalf.length > 0
    ) {
      return (
        <ModalImage
          small={horsePhoto.amazons3URLHalf}
          medium={horsePhoto.amazons3URLHalf}
          large={horsePhoto.amazons3URL}
          alt="Hello World!"
          key={horsePhoto.id}
        />
      );
    }
    if (
      horsePhoto &&
      horsePhoto.amazons3URL &&
      horsePhoto.amazons3URL.length > 0
    ) {
      return (
        <ModalImage
          small={horsePhoto.amazons3URL}
          medium={horsePhoto.amazons3URL}
          alt="Hello World!"
          key={horsePhoto.id}
        />
      );
    } else {
      return (
        <Image src="/images/photos/blank.png" alt="Avatar" rounded responsive />
      );
    }
  }

  function getDisplayName() {
    if (horsePhoto && horsePhoto.title) {
      return horsePhoto.title;
    } else {
      if (horsePhoto && horsePhoto.description) {
        return horsePhoto.description;
      } else {
        return (
          '(id, hpId): (' + horsePhoto.id + ', ' + horsePhoto.horsePhotoId + ')'
        );
      }
    }
  }

  function getUploadDate() {
    if (horsePhoto && horsePhoto.createdAt) {
      return dateToText(horsePhoto.createdAt);
    } else {
      return 'Unknown Date';
    }
  }

  function handleDelete(e) {
    if (e) {
      e.preventDefault();
    }
    const horsePhotoId = horsePhoto.horsePhotoId;
    const horseId = horse.id;
    var deleteObj = { horseId, horsePhotoId };

    horse.deletePhoto(dispatch, horsePhoto.id);
    onDelete(deleteObj);
  }

  function getDeleteButton() {
    if (onDelete) {
      return (
        <Button onClick={handleDelete} variant="danger">
          Delete
        </Button>
      );
    }
  }

  function handleUp(e) {
    if (e) {
      e.preventDefault();
    }
    if (horse) {
      horse.movePhotoUp(dispatch, horsePhoto.id);
    }
  }

  function getUpButton() {
    return (
      <Button onClick={handleUp} variant="warning">
        Up
      </Button>
    );
  }

  function handleDown(e) {
    if (e) {
      e.preventDefault();
    }
    if (horse) {
      horse.movePhotoDown(dispatch, horsePhoto.id);
    }
  }

  function getDownButton() {
    return (
      <Button onClick={handleDown} variant="warning">
        Down
      </Button>
    );
  }

  function handleMakeMainPhoto(e) {
    if (e) {
      e.preventDefault();
    }
    const photoId = horsePhoto.id;
    const horseId = horse.id;
    onMakeMainPhoto({ horseId, photoId });
  }

  function isMainPhoto() {
    if (horse && horse.photo) {
      if (horsePhoto) {
        if (horse.photo.id === horsePhoto.id) return true;
      }
    }
    return false;
  }

  function getMakeMainPhotoButton() {
    if (onMakeMainPhoto && !isMainPhoto()) {
      return (
        <Button onClick={handleMakeMainPhoto} variant="info">
          Make Main Photo
        </Button>
      );
    } else if (isMainPhoto()) {
      return <Button variant="warning">Main Photo</Button>;
    }
  }

  return (
    <td>
      <div className="card">
        {renderImgTag()}
        <Stack spacing={2}>
          {getMakeMainPhotoButton()}
          {getUpButton()}
          {getDownButton()}
          <h4>{getDisplayName()}</h4>
          <h5>{getUploadDate()}</h5>
          {getDeleteButton()}
        </Stack>
      </div>
    </td>
  );
}
