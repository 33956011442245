import React from 'react';
import HorsePhotoCard from './HorsePhotoCard';
import { Table } from 'react-bootstrap';

export default function HorsePhotosGroup({ horse, onMakeMainPhoto, onDelete }) {
  function renderHorsePhotos() {
    if (horse && horse.photos) {
      return horse.photos.map((horsePhoto) => {
        return (
          <tr key={horsePhoto.id}>
            <HorsePhotoCard
              horsePhoto={horsePhoto}
              onDelete={onDelete}
              onMakeMainPhoto={onMakeMainPhoto}
              horse={horse}
            />
          </tr>
        );
      });
    }
    return null;
  }

  return (
    <Table striped bordered hover>
      <tbody>{renderHorsePhotos()}</tbody>
    </Table>
  );
}
